import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({breakpoints, spacing}) => ({
    root: {
      paddingTop: spacing(6),
      paddingBottom: spacing(3),
      minHeight: 'calc(100vh - 198px)',
      [breakpoints.down(552)]: {
        paddingTop: spacing(3),
      },
    },
    flexWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
      marginTop: spacing(3),
    },
  }),
  {index: 1},
)

export const useDialogStyles = makeStyles(
  ({breakpoints, spacing}) => ({
    btnGroup: {
      margin: spacing(2.5, 0),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: spacing(2),
    },
    container: {
      padding: 0,
      justifyContent: 'flex-end',
      gap: spacing(2),
    },
    paper: {
      padding: spacing(2.5),
    },
    primaryBtn: {
      margin: 0,
      width: 92,
    },
    secondaryBtn: {
      width: 85,
    },
    text: {
      [breakpoints.down(552)]: {
        textAlign: 'center',
      },
    },
  }),
  {index: 2},
)
