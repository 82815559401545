import React, {Fragment} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import AppTypography from 'src/components/elements/typography/AppTypography'
import CloseIcon from 'src/components/elements/icons/Close'
import AppDivider from 'src/components/elements/AppDivider'
import Modal from '@material-ui/core/Modal'
import clsx from 'clsx'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({breakpoints, palette, spacing, shadows}) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down(900)]: {
      margin: spacing(2),
    },
  },
  bigPaper: {
    maxWidth: 768,
    minWidth: 768,
  },
  biggerPaper: {
    maxWidth: 1044,
    minWidth: 1044,
  },
  normalPaper: {
    maxWidth: 552,
    minWidth: 552,
  },
  paper: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    padding: spacing(2.5),
    borderRadius: spacing(1.5),
    overflowY: 'auto',
    maxHeight: '80%',
    [breakpoints.down(900)]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
}))

function AppModal(props: {
  title?: string
  open: boolean
  handleClose?: () => void
  canClose?: boolean
  size?: 'normal' | 'big' | 'bigger'
  children?: React.ReactNode
}) {
  const classes = useStyles()
  const {
    title,
    children,
    open,
    handleClose,
    canClose = true,
    size = 'normal',
  } = props

  const paperClasses = [
    classes.paper,
    size === 'normal' ? classes.normalPaper : undefined,
    size === 'big' ? classes.bigPaper : undefined,
    size === 'bigger' ? classes.biggerPaper : undefined,
  ]

  return (
    <Fragment>
      {/* @ts-ignore */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.modal}
      >
        <Fade in={open}>
          <div className={clsx(paperClasses)}>
            <div className="flex flex-row justify-between items-center">
              {title ? (
                <AppTypography variant="displaySmall" neutralColor={900}>
                  {title}
                </AppTypography>
              ) : (
                <div />
              )}

              {canClose && (
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            <AppDivider className="my-5" />
            <div>{children}</div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  )
}

export default AppModal
