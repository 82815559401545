import {PageType} from 'src/types/page'
import {useEffect, useState} from 'react'
import InnerHTML from 'src/components/helpers/InnerHTML'

function PageFooterHtml(props: {page: PageType}) {
  const {page} = props

  const [showClientSideComponents, setShowClientSideComponents] =
    useState<boolean>(false)

  useEffect(() => {
    setShowClientSideComponents(true)
  }, [])

  if (!page.footer_html) {
    return null
  }

  //footer html is not rendered on the server side
  //it mostly have analytics scripts and other non important to render on server and first load
  if (!showClientSideComponents) {
    return null
  }

  //use key to force re-render
  return <InnerHTML key={page.id} html={page.footer_html} />
}

export default PageFooterHtml
