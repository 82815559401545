import {makeStyles} from '@material-ui/core/styles'
import {SelectProps} from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {FormOption} from 'src/types/form'
import ArrowDownIcon from 'src/components/elements/icons/ArrowDown'
import InputError from 'src/components/elements/helpers/InputError'
import EnhancedInput from 'src/components/forms/textField/EnhancedInput'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {isNumber} from 'src/utils/other'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({spacing}) => ({
    root: {
      padding: spacing(9 / 8, 2.5),
    },
    icon: {
      right: spacing(21 / 8),
      top: 'calc(50% - 7px)',
    },
    typography1: {
      height: spacing(22 / 8),
    },
    typography2: {
      marginTop: spacing(0.5),
    },
  }),
  {index: 1},
)

interface Props extends Omit<SelectProps, 'label' | 'name'> {
  description?: React.ReactNode
  label?: string
  message?: string
  withLabel?: boolean
  options: FormOption[]
  optional?: boolean
  fullWidth?: boolean
  name?: string | any
}

const AppSelect = (props: Props) => {
  const {
    className,
    description,
    error,
    name,
    label,
    options,
    optional,
    placeholder,
    message,
    style,
    withLabel,
    fullWidth = true,
    ...otherProps
  } = props
  const classes = useStyles()

  return (
    <FormControl className={className} fullWidth={fullWidth} style={style}>
      {withLabel ? (
        <div className="flex flex-row mb-1 gap-1">
          <AppTypography
            variant="action"
            neutralColor={800}
            component="label"
            htmlFor={name}
          >
            {label}
          </AppTypography>
          {optional ? null : (
            <AppTypography
              component="span"
              className="text-primary-main"
              variant="body"
            >
              *
            </AppTypography>
          )}
        </div>
      ) : null}
      <Select
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
        displayEmpty
        name={name}
        label={label}
        renderValue={() => {
          const value = otherProps.value
          const isNumberValue = isNumber(value)
          let renderedValue: any = value
          if (options) {
            const find = options.find((option) =>
              isNumberValue
                ? Number(option.value) === Number(`${value}`)
                : option.value === value,
            )
            renderedValue = find?.label
          }
          return (
            <AppTypography
              neutralColor={value ? 700 : 400}
              variant="body"
              className={classes.typography1}
            >
              {renderedValue ? renderedValue : placeholder}
            </AppTypography>
          )
        }}
        error={error}
        input={<EnhancedInput />}
        aria-describedby={`${name}-helper-text`}
        IconComponent={ArrowDownIcon}
        {...otherProps}
      >
        {options?.map(({label, value}, index: number) => (
          <MenuItem value={value as string} key={index}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && message ? (
        <InputError>{message}</InputError>
      ) : (
        <AppTypography
          variant="subHeading"
          neutralColor={500}
          className={classes.typography2}
        >
          {description}
        </AppTypography>
      )}
    </FormControl>
  )
}

export default AppSelect
