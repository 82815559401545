import {useState, createContext, useContext} from 'react'

interface HeaderContextProps {
  openSidebar: boolean
  anchorElement: HTMLElement | null
  toggleOpenSidebar: () => void
  handleCloseMenu: () => void
  handleOpenMenu: (node: HTMLElement) => void
}

export const HeaderContext = createContext<HeaderContextProps | undefined>(
  undefined,
)

HeaderContext.displayName = 'HeaderContext'

export const useHeaderContext = () => {
  const context = useContext(HeaderContext)
  if (context === undefined)
    throw new Error(`${HeaderContext.displayName} must be used within provider`)

  return context
}

export default function HeaderProvider(props: {children: React.ReactChild}) {
  const [openSidebar, setOpenSidebar] = useState(false)
  const toggleOpenSidebar = () => setOpenSidebar(!openSidebar)
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  const handleOpenMenu = (node: HTMLElement) => {
    setAnchorElement(node)
  }
  const handleCloseMenu = () => setAnchorElement(null)

  return (
    <HeaderContext.Provider
      value={{
        openSidebar,
        anchorElement,
        toggleOpenSidebar,
        handleCloseMenu,
        handleOpenMenu,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  )
}
