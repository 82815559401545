import {
  SET_SELECTED_PROPERTY,
  SET_SELECTED_LOCATION,
  SET_SELECTED_DATE_RANGE,
  SET_SELECTED_GUESTS,
  SET_SELECTED_AMENITIES,
  SET_SEARCH_PAGE_NO,
  SET_SORTBY_FILTER,
  SET_MORE_FILTER,
  SET_PRICE_RANGE_FILTER,
  SET_MAP_BOUNDS,
  INIT_PROPERTY_STATE,
  SET_MAP_CENTER_BOUNDS,
  INIT_PROPERTY_STATE_FROM_URL,
  SET_CURRENT_MAP_ZOOM,
  SET_TAB_VIEW,
  SET_ERROR_CODE,
  SET_CLICKED_PROPERTY_UID,
  SET_SCROLL_Y,
  SET_LAST_SEARCH_PARAMS,
  SET_SEARCH_LOADING,
  SET_SEARCH_DATA,
} from 'src/store/actionTypes'
import {PropertySearchStoreState} from 'src/store/reducers/propertySearchReducer'
import {
  DateRange,
  IMoreFilters,
  LocationSearch,
  MapBounds,
  PriceRange,
  SearchPropertiesParams,
  SortByFilter,
} from 'src/types/search'
import {SearchPropertyListProps, SearchPropertyProps} from 'src/types/property'
import {GuestSelectProps} from 'src/types/guestSelect'
import {getSearchParams} from 'src/utils/property'
import isEqual from 'lodash-es/isEqual'
import axios, {CancelTokenSource} from 'axios'
import {publicGetSearchPropertiesApi} from 'src/services/api/property'
import {ERRORS} from 'src/constants/googleMap'
import {Dispatch} from 'redux'
import {NextRouter} from 'next/router'
import {getCleanSearchParams} from 'src/utils/search'
import * as gtm from 'src/lib/gtm'
import {sendSearchDataCollection} from 'src/hooks/postDataCollection'

export const setMapBounds = (payload: MapBounds | null) => {
  return {
    type: SET_MAP_BOUNDS,
    payload,
  }
}

export const setMapCenterBounds = (
  payload: {lat: number; lng: number} | null,
) => {
  return {
    type: SET_MAP_CENTER_BOUNDS,
    payload,
  }
}

export const setSelectedProperty = (payload: SearchPropertyProps | null) => {
  return {
    type: SET_SELECTED_PROPERTY,
    payload,
  }
}
export const setSelectedLocation = (payload: LocationSearch) => {
  return {
    type: SET_SELECTED_LOCATION,
    payload,
  }
}
export const setSelectedDate = (payload: DateRange) => {
  return {
    type: SET_SELECTED_DATE_RANGE,
    payload,
  }
}
export const setSelectedGuests = (payload: GuestSelectProps) => {
  return {
    type: SET_SELECTED_GUESTS,
    payload,
  }
}

export const setCurrentMapZoom = (zoom: number) => {
  return {
    type: SET_CURRENT_MAP_ZOOM,
    payload: zoom,
  }
}
export const setSelectedAmenities = (payload: string[]) => {
  return {
    type: SET_SELECTED_AMENITIES,
    payload,
  }
}
export const setSearchPageNo = (payload: number) => {
  return {
    type: SET_SEARCH_PAGE_NO,
    payload,
  }
}

export const setSearchData = (payload: SearchPropertyListProps) => {
  return {
    type: SET_SEARCH_DATA,
    payload,
  }
}

export const setSortByFilter = (payload: SortByFilter) => {
  return {
    type: SET_SORTBY_FILTER,
    payload,
  }
}

export const setMoreFilter = (payload: IMoreFilters) => {
  return {
    type: SET_MORE_FILTER,
    payload,
  }
}

export const setPriceRangeFilter = (payload: PriceRange) => {
  return {
    type: SET_PRICE_RANGE_FILTER,
    payload,
  }
}

export const initPropertyState = () => {
  return {
    type: INIT_PROPERTY_STATE,
  }
}

export const initPropertyStateFromUrl = (
  payload: Partial<PropertySearchStoreState>,
) => {
  return {
    type: INIT_PROPERTY_STATE_FROM_URL,
    payload,
  }
}

let fetchCancelSource: CancelTokenSource | null = null
export const fetchSearchData =
  (
    page: number,
    force?: boolean,
    useQuery?: boolean,
    routerInstance?: NextRouter,
  ) =>
  (dispatch: Dispatch, getState: any) => {
    const state = getState()
    const {lastSearchParams} = state.propertySearch
    const {login} = state.user
    const searchParams = getSearchParams(page)

    //dont double fetch
    if (isEqual(searchParams, lastSearchParams) && !force) {
      return
    }

    dispatch(setLastSearchParams(searchParams))
    if (useQuery && routerInstance) {
      let newQuery = getCleanSearchParams(searchParams, routerInstance.query)

      //using replace and shallow so we dont reload next JS props data as a fetch HEAD call
      routerInstance.replace(
        {pathname: routerInstance.pathname, query: newQuery},
        undefined,
        {
          shallow: true,
        },
      )
    }

    if (!searchParams.bounds) {
      return
    }

    //start loader
    dispatch(setErrorCode(null))
    dispatch(setSearchLoading(true))

    //will cancel last fetch
    if (fetchCancelSource) {
      fetchCancelSource.cancel()
    }

    //set new token
    fetchCancelSource = axios.CancelToken.source()

    sendSearchDataCollection() //search data collection

    publicGetSearchPropertiesApi(searchParams, fetchCancelSource.token)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(setErrorCode(ERRORS.OTHER))
          dispatch(setSearchLoading(false))
          return
        }

        if (response.data.total === 0) {
          dispatch(setErrorCode(ERRORS.NO_DATA))
        }

        dispatch(setSearchData(response.data))
        dispatch(setSearchLoading(false))

        //off thread
        setTimeout(() => {
          gtm.viewSearchProperties(
            response.data.items,
            login ? login.user : null,
          )
        }, 1)
      })
      .catch((err) => {
        //check if the request was cancel with our token
        if (axios.isCancel(err)) {
          return
        }
        dispatch(setSearchLoading(false))
        dispatch(setErrorCode(ERRORS.OTHER))
      })
  }

export const setTabView = (tabView: 'list' | 'map') => {
  return {
    type: SET_TAB_VIEW,
    payload: tabView,
  }
}

export const setErrorCode = (payload: string | null) => {
  return {
    type: SET_ERROR_CODE,
    payload,
  }
}

export const setClickedPropertyUid = (payload: string | null) => {
  return {
    type: SET_CLICKED_PROPERTY_UID,
    payload,
  }
}

export const setScrollY = (payload: number) => {
  return {
    type: SET_SCROLL_Y,
    payload,
  }
}

export const setLastSearchParams = (payload: SearchPropertiesParams | null) => {
  return {
    type: SET_LAST_SEARCH_PARAMS,
    payload,
  }
}

export const setSearchLoading = (payload: boolean) => {
  return {
    type: SET_SEARCH_LOADING,
    payload,
  }
}
