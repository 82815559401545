import {PropertyProps} from 'src/types/property'
import {BookingProps} from 'src/types/booking'
import {ConnectedUserType} from 'src/types/user'
import {LOCALE_FR} from 'src/constants/locale'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const MICROSOFT_CLARITY_ID = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID

export const getUserForGtm = (user: ConnectedUserType | null) => {
  if (!user) {
    return null
  }
  const nameSplit: string[] = (user.name || '').split(' ')
  const firstName = nameSplit.shift() as string
  const lastName = nameSplit.join(' ')

  const phoneNumbers = user.user_phone_numbers || []
  const phoneNumber = phoneNumbers.find((phoneNumber) => phoneNumber.is_primary)

  return {
    created_at: user.created_at,
    id: user.id,
    email: user.email,
    phone_number: phoneNumber ? phoneNumber.phone_number : null,
    first_name: firstName,
    last_name: lastName,
    country: 'CA',
  }
}

export const getBasePropertyForGtm = (property: PropertyProps) => {
  const address = property.addresses.find((a) => a.locale === LOCALE_FR)

  return {
    item_id: property.uid,
    item_name: property.name_fr,

    quantity: 1,
    ...(address
      ? {
          item_category: address.country,
          item_category2: address.state,
          item_category3: address.administrative_area,
          item_category4: address.mrc,
        }
      : {}),
  }
}
export const pageview = (url: any, user: ConnectedUserType | null) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'pageview',
    page: url,
    user: getUserForGtm(user),
  })
}

export const initEcommerce = () => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    ecommerce: null,
  })
}

export const viewSearchProperties = (
  properties: PropertyProps[],
  user: ConnectedUserType | null,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'view_item_list',
    user: getUserForGtm(user),
    ecommerce: {
      items: properties.map((p) => {
        return {
          ...getBasePropertyForGtm(p),
          ...(p.basic_pricing
            ? {
                price: p.basic_pricing.base_rate,
              }
            : {}),
        }
      }),
    },
  })
}

export const viewUniquePropertyPageUrl = (
  property: PropertyProps,
  user: ConnectedUserType | null,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'view_item',
    user: getUserForGtm(user),
    ecommerce: {
      items: [
        {
          ...getBasePropertyForGtm(property),
          ...(property.basic_pricing
            ? {
                price: property.basic_pricing.base_rate,
              }
            : {}),
        },
      ],
    },
  })
}

export const clickSearchProperty = (
  property: PropertyProps,
  user: ConnectedUserType | null,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'select_item',
    user: getUserForGtm(user),
    ecommerce: {
      items: [
        {
          ...getBasePropertyForGtm(property),
          ...(property.basic_pricing
            ? {
                price: property.basic_pricing.base_rate,
              }
            : {}),
        },
      ],
    },
  })
}

export const onBookingCheckoutCanceled = (
  user: ConnectedUserType | null,
  property: PropertyProps,
  total12: number,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'remove_from_cart',
    user: getUserForGtm(user),
    ecommerce: {
      items: [
        {
          ...getBasePropertyForGtm(property),
          price: total12,
        },
      ],
    },
  })
}

export const onBookingCheckoutStarted = (
  user: ConnectedUserType | null,
  property: PropertyProps,
  total12: number,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  //always trigger
  dataLayer.push({
    event: 'add_to_cart',
    user: getUserForGtm(user),
    ecommerce: {
      items: [
        {
          ...getBasePropertyForGtm(property),
          price: total12,
        },
      ],
    },
  })

  //if login, we start checkout process
  if (user) {
    dataLayer.push({
      event: 'begin_checkout',
      user: getUserForGtm(user),
      ecommerce: {
        items: [
          {
            ...getBasePropertyForGtm(property),
            price: total12,
          },
        ],
      },
    })
  }
}

export const onBookingCheckoutCompleted = (
  property: PropertyProps,
  booking: BookingProps,
  user: ConnectedUserType | null,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'purchase',
    user: getUserForGtm(user),
    ecommerce: {
      currency: 'CAD',
      value: booking.prices.total12,
      transaction_id: booking.uid,
      items: [
        {
          ...getBasePropertyForGtm(property),
          price: booking.prices.total12,
        },
      ],
    },
  })
}

export const userSignUp = (
  //method: 'password' | 'google' | 'facebook',
  user: ConnectedUserType,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any
  const userData = getUserForGtm(user)

  dataLayer.push({
    event: 'sign_up',
    //method: method,
    user: userData,
  })
}

export const userLogin = (
  //method: 'password' | 'google' | 'facebook',
  user: ConnectedUserType,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  const userData = getUserForGtm(user)
  dataLayer.push({
    event: 'login',
    // method: method,
    user: userData,
  })
}
