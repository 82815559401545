import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {useRouter} from 'next/router'
import {ADMIN_PAGE, AUTH_PAGE, HOME_PAGE} from 'src/constants/route'
import {usePrevious} from 'src/hooks/other'
import {useIsLoggedIn} from 'src/hooks/user'

const REDIRECT_URL = '__redirect__'

interface RedirectContextProps {
  loginRequired: boolean
  setRedirect: () => void
  clearRedirect: () => void
}

export const RedirectContext = createContext<RedirectContextProps | undefined>(
  undefined,
)

RedirectContext.displayName = 'RedirectContext'

export const useRedirectContext = () => {
  const context = useContext(RedirectContext)
  if (context === undefined)
    throw new Error(
      `${RedirectContext.displayName} must be used within provider`,
    )

  return context
}

function clearRedirect() {
  localStorage.removeItem(REDIRECT_URL)
}

function getRedirect() {
  return localStorage.getItem(REDIRECT_URL)
}

function shouldRedirect(path: string | null) {
  return window.location.href !== path
}

function isExcludedPath(path: string) {
  switch (path) {
    case AUTH_PAGE:
      return true
  }

  return false
}

function isLoginRequiredPath(path: string) {
  if (path.startsWith(ADMIN_PAGE)) {
    return true
  }
  return false
}

export default function RedirectProvider(props: {children: React.ReactChild}) {
  const [loginRequired, setLoginRequired] = useState(false)

  const router = useRouter()
  const {pathname} = router
  const isLoggedIn = useIsLoggedIn()
  const previousLoggedIn = usePrevious(isLoggedIn)

  const setRedirect = useCallback(() => {
    if (loginRequired) {
      return
    }

    if (!isExcludedPath(pathname)) {
      localStorage.setItem(REDIRECT_URL, window.location.href)
    }

    setLoginRequired(isLoginRequiredPath(pathname))
  }, [loginRequired, pathname])

  const redirectToPath = useCallback(
    (redirect: string | null) => {
      if (!shouldRedirect(redirect)) {
        return
      }

      if (pathname === HOME_PAGE && redirect) {
        // prevent go to home page
        window.location.href = redirect
        return
      }

      if (redirect) {
        // social login redirect
        router.push(redirect)
      }
    },
    [pathname, router],
  )

  useEffect(() => {
    const redirect = getRedirect()
    if (!previousLoggedIn && isLoggedIn) {
      // when login
      redirectToPath(redirect)
      return
    }

    if (!isLoggedIn && previousLoggedIn) {
      // if logged out then no need to set redirect
      return
    }

    if (isLoggedIn) {
      // if logged in then clear redirect
      clearRedirect()
      setLoginRequired(false)
      return
    }

    setRedirect()
  }, [isLoggedIn, previousLoggedIn, redirectToPath, setRedirect])

  return (
    <RedirectContext.Provider
      value={{
        loginRequired,
        setRedirect,
        clearRedirect,
      }}
    >
      {props.children}
    </RedirectContext.Provider>
  )
}
