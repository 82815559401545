import clsx from 'clsx'
import {Color} from '@material-ui/core'
import {palette} from 'src/components/materialTheme/palette'
import {AppTypographyVariant} from 'src/types/typography'

type Props = {
  className?: string
  style?: any
  component?: keyof HTMLElementTagNameMap
  neutralColor?: keyof Color
  htmlColor?: string
  htmlFor?: string
  children?: React.ReactNode
  variant: AppTypographyVariant
  dangerouslySetInnerHTML?: {__html: string}
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  id?: string
}

export default function AppTypography({
  className,
  htmlColor,
  neutralColor,
  style,
  variant,
  children,
  ...props
}: Props) {
  const styles = {
    color: neutralColor ? palette?.grey![neutralColor] : htmlColor,
  }

  let Component: any = 'p'

  if (props.component) {
    Component = props.component
  }

  return (
    <Component
      className={clsx(`g-typo-${variant}`, className)}
      style={{...style, ...styles}}
      {...props}
    >
      {children}
    </Component>
  )
}
