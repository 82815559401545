import {makeStyles} from '@material-ui/core/styles'
import {globalZIndex} from 'src/utils/styles'

interface Props {
  isTop: boolean
  isHomepage: boolean
}

export const useStyles = makeStyles(
  ({breakpoints, palette, spacing}) => ({
    appBar: {
      background: palette.background.paper,
      border: '1px solid #EAEEF5',
      position: 'fixed',
      //width: '100%',
      zIndex: globalZIndex.header,
      left: 0,
      right: 0,
      top: 0,
      [breakpoints.down(900)]: {
        // border: 'none',
        // background: 'black',
      },

      '&.-top': {
        position: 'absolute',
        //background: 'black',
        //  border: 'none',

        [breakpoints.down(900)]: {
          //  background: 'black',
        },

        '&.-homepage': {
          background: 'rgba(0,0,0,0)',
          border: 'none',
          [breakpoints.down(900)]: {
            background: 'rgba(0,0,0,0)',
          },
        },
      },
    },
    flexWrapper: {
      display: 'flex',
      alignItems: 'center',
      [breakpoints.down(1128)]: {
        gridGap: spacing(3),
      },
    },
    menuBtn: {
      height: spacing(6),
      width: spacing(6),
      borderRadius: 0,
      padding: '8px',
      //  marginRight: -9,
      // paddingRight: 0,
    },
    transparentBtn: ({isTop}: Props) => ({
      background: isTop ? 'transparent' : undefined,
      height: '40px',
      //   borderColor: isTop ? palette.text.primary : undefined,
      //    color: isTop ? palette.text.primary : undefined,
      padding: `10px 20px`,
      '& span': {
        lineHeight: 'normal',
      },
    }),
    linkBtn: ({isTop}: Props) => ({
      //color: isTop ? palette.text.primary : undefined,
      color: palette.grey[600],
      padding: `8px 8px`,
      height: '40px',
    }),
    headerActionBtn: ({isTop}: Props) => ({
      listStyle: 'none',
      gridGap: spacing(1.5),
      ...(!isTop && {flex: '1'}),
      justifyContent: 'flex-end',
      [breakpoints.down(1360)]: {
        gridGap: spacing(1),
      },
      [breakpoints.down(1128)]: {
        display: 'none',
      },
    }),
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'space-between',
      margin: '0 auto',
      height: spacing(8),
      width: '100%',
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      [breakpoints.down(1360)]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      [breakpoints.down(1128)]: {
        height: spacing(8),
      },
    },
    rightContainerSm: {
      display: 'none',
      [breakpoints.down(1128)]: {
        display: 'block',
      },
    },
    rightContainerLg: {
      display: 'block',
      [breakpoints.down(1128)]: {
        display: 'none',
      },
    },
    chevronDown: {
      transform: 'rotate(180deg)',
      marginTop: 5,
    },
  }),
  {index: 2},
)
