import {useState, useEffect} from 'react'
import {useTranslation} from 'next-i18next'
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {Stripe} from '@stripe/stripe-js'
import AppModal from 'src/components/elements/AppModal'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import AppTextField, {Label} from 'src/components/forms/textField/AppTextField'
import InputError from 'src/components/elements/helpers/InputError'
import {
  addPaymentMethodApi,
  getCustomerInformationApi,
  createCustomerApi,
} from 'src/services/api/stripe'
import {useCurrentLocale} from 'src/hooks/locale'
import {handleHttpException} from 'src/services/http'
import {onChangeStringHandler, useIsMounted} from 'src/utils/dom'
import {useStylesSteps} from 'src/components/modules/admin/myAccount/Payment/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import Visible from 'src/components/helpers/Visible'

const stripeKey: any = process.env.NEXT_PUBLIC_STRIPE_KEY

export function PaymentForm(props: {
  toggleOpen: () => void
  onCreated: () => void
  loading: boolean
  setLoading: (loading: boolean) => void
  overwriteUserId?: number
  canCancel?: boolean
}) {
  const {
    toggleOpen,
    onCreated,
    setLoading,
    loading,
    overwriteUserId,
    canCancel = true,
  } = props

  const [name, setName] = useState('')
  const [hasStripe, setHasStripe] = useState(false)
  const [error, setError] = useState<any>(null)

  const {t} = useTranslation(['account', 'common'])
  const classes = useStylesSteps()
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (error && elements) {
      elements.getElement('card')?.focus()
      return
    }
  }, [elements, error])

  async function createNewCustomer() {
    try {
      const customerInfo: any = await createCustomerApi(overwriteUserId)
      if (customerInfo.status === 200) {
        setHasStripe(true)

        return
      }

      setHasStripe(false)
    } catch {
      setHasStripe(false)
    }
  }

  async function getCustomer() {
    try {
      const customerInfo = await getCustomerInformationApi(overwriteUserId)
      if (customerInfo.status === 200) {
        setHasStripe(true)
        return
      }
      setHasStripe(false)
    } catch {
      setHasStripe(false)
    }
  }

  async function createPaymentMethod(paymentMethodId: string) {
    addPaymentMethodApi(paymentMethodId, overwriteUserId)
      .then(() => {
        toggleOpen()
        onCreated()
      })
      .catch(handleHttpException(setError))
      .finally(() => setLoading(false))
  }

  const handleSubmit = () => async () => {
    setError(null)
    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    if (!card) {
      return
    }

    setLoading(true)
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {name},
    })
    if (payload.error) {
      setError(payload.error.message)
      setLoading(false)
      return
    }

    if (hasStripe) {
      await createPaymentMethod(payload.paymentMethod.id)
      return
    }

    await createNewCustomer()
    await getCustomer()
    await createPaymentMethod(payload.paymentMethod.id)
  }

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overwriteUserId])

  return (
    <div
      className={clsx(
        classes.addModal,
        loading ? classes.isLoading : '',
        'flex flex-col',
      )}
    >
      {loading && (
        <div
          className={clsx(
            classes.circularProgress,
            'flex flex-col justify-center items-center',
          )}
        >
          <CircularProgress color="primary" size={20} />
        </div>
      )}
      <AppTextField
        placeholder={t('Name')}
        label={t('full_name_on_card')}
        required
        value={name}
        onChange={onChangeStringHandler(setName)}
      />
      <div>
        <Label label={t('card_informations')} />
        <CardElement className={classes.stripeElement} />
        <InputError>{error}</InputError>
      </div>
      <div className="flex flex-row justify-center items-center gap-4 mt-4">
        {canCancel && (
          <AppRoundedButton
            className={classes.btnCancel}
            variant="outlined"
            onClick={toggleOpen}
            size="small"
            disabled={loading}
          >
            {t('cancel', {ns: 'common'})}
          </AppRoundedButton>
        )}
        <AppRoundedButton
          className={classes.btnSave}
          onClick={handleSubmit()}
          variant="contained"
          color="primary"
          disabled={!stripe || !elements || !name || loading}
        >
          {t('save', {ns: 'common'})}
        </AppRoundedButton>
      </div>
    </div>
  )
}

function AddPaymentMethodModal(props: {
  open: boolean
  toggleOpen: () => void
  onCreated: () => void
  overwriteUserId?: number
}) {
  const {open, toggleOpen, onCreated, overwriteUserId} = props

  const {t} = useTranslation('booking')
  const currentLocale = useCurrentLocale()
  const classes = useStylesSteps()

  const [loading, setLoading] = useState(false)
  const [stripeRef, setStripeRef] = useState<Stripe | null>(null)
  const {current: mounted} = useIsMounted()

  useEffect(() => {
    ;(async () => {
      const stripeJs = await import('@stripe/stripe-js')
      if (!stripeJs) return
      if (!mounted) return

      let stripeInstance = await stripeJs.loadStripe(stripeKey, {
        locale: currentLocale ?? 'en',
      })
      if (!mounted) return

      if (!stripeInstance) return

      setStripeRef(stripeInstance)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale])

  const handleClose = () => {
    if (loading) {
      return //cant close modal if loading
    }

    toggleOpen()
  }

  return (
    <AppModal
      title={t('add_a_payment_method')}
      open={open}
      handleClose={handleClose}
    >
      <div className={clsx(loading ? classes.isLoading : '', 'flex flex-col')}>
        <Visible when={loading}>
          <div
            className={clsx(
              classes.circularProgress,
              'flex flex-col justify-center items-center',
            )}
          >
            <CircularProgress color="primary" size={20} />
          </div>
        </Visible>
        {stripeRef && (
          <Elements stripe={stripeRef}>
            <PaymentForm
              overwriteUserId={overwriteUserId}
              loading={loading}
              setLoading={setLoading}
              toggleOpen={toggleOpen}
              onCreated={onCreated}
            />
          </Elements>
        )}
      </div>
    </AppModal>
  )
}

export default AddPaymentMethodModal
