import {useEffect, useState} from 'react'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useCurrentLocale} from 'src/hooks/locale'
import {useStylesSteps} from 'src/components/modules/admin/myAccount/Payment/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Elements} from '@stripe/react-stripe-js'
import {PaymentForm} from 'src/components/modules/dialog/AddPaymentMethod'
import {useTranslation} from 'next-i18next'
import {Stripe} from '@stripe/stripe-js'
import {useIsMounted} from 'src/utils/dom'
import clsx from 'clsx'

const stripeKey: any = process.env.NEXT_PUBLIC_STRIPE_KEY

export default function FormStep2(props: {
  onCompleted: () => void
  token: string
}) {
  const currentLocale = useCurrentLocale()
  const classes = useStylesSteps()
  const {t} = useTranslation('auth')
  const [loading, setLoading] = useState(false)
  const [stripeRef, setStripeRef] = useState<Stripe | null>(null)
  const {current: mounted} = useIsMounted()

  useEffect(() => {
    ;(async () => {
      const stripeJs = await import('@stripe/stripe-js')
      if (!stripeJs) return
      if (!mounted) return

      let stripeInstance = await stripeJs.loadStripe(stripeKey, {
        locale: currentLocale ?? 'en',
      })
      if (!mounted) return

      if (!stripeInstance) return

      setStripeRef(stripeInstance)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale])

  const handleClose = () => {
    if (loading) return //cant close modal if loading
    props.onCompleted()
  }

  return (
    <>
      <div className="mb-4">
        <AppTypography variant="caption">
          {t('onboarding_caption_2')}
        </AppTypography>
      </div>
      <div className={clsx(loading ? classes.isLoading : '', 'flex flex-col')}>
        {loading && (
          <div
            className={clsx(
              classes.circularProgress,
              'flex flex-col justify-center items-center',
            )}
          >
            <CircularProgress color="primary" size={20} />
          </div>
        )}
        {stripeRef && (
          <Elements stripe={stripeRef}>
            <PaymentForm
              canCancel={false}
              loading={loading}
              setLoading={setLoading}
              toggleOpen={() => {}}
              onCreated={handleClose}
            />
          </Elements>
        )}
      </div>
    </>
  )
}
