import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {useForm, Resolver, FieldError} from 'react-hook-form'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTextField from 'src/components/forms/textField/AppTextField'
import AppTypography from 'src/components/elements/typography/AppTypography'
import CheckBoxIcon from 'src/components/elements/icons/CheckBox'
import {publicResetPasswordRequestApi} from 'src/services/api/auth'
import {ValidationError, fieldError} from 'src/hooks/form'
import {validateEmail} from 'src/services/validation/validateModules'
import {useStyles} from './styles'
import clsx from 'clsx'

type FormValues = {
  email: string
}

const validate: Record<
  keyof FormValues,
  (value: string) => string | undefined
> = {
  email: validateEmail,
}

const formResolver: Resolver<FormValues> = async (values: FormValues) => {
  const errors: Record<string, FieldError> = {}
  for (let field in values) {
    const key = field as keyof FormValues
    const message = validate[key](values[key])

    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values,
  }
}

export default function Form() {
  const {t} = useTranslation('auth')
  const classes = useStyles()

  const [serverError, setServerError] = useState<ValidationError<any>>(null)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: formResolver,
  })

  const emailError = fieldError('email', {
    form: errors,
    response: serverError,
  })

  const onSubmit = (formData: FormValues) => {
    setServerError(null)
    setSuccess(false)
    setSubmitting(true)
    publicResetPasswordRequestApi(formData.email)
      .then(() => setSuccess(true))
      .catch((ex) => setServerError(ex.response.data))
      .finally(() => setSubmitting(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={clsx(classes.form, 'flex flex-col')}>
        <AppTypography variant="body" neutralColor={600}>
          {t('forgot_password_description')}
        </AppTypography>
        <Success success={success} />
        <AppTextField
          label={t('email')}
          placeholder={t('email')}
          error={Boolean(emailError)}
          message={emailError ? t(emailError) : ''}
          inputProps={register('email', {
            required: `${t('Email is required')}`,
          })}
        />
        <AppButton
          variant="contained"
          color="primary"
          fullWidth
          style={{marginBottom: 20}}
          type="submit"
          disabled={submitting}
        >
          {t('send_the_magic_link')}
        </AppButton>
      </div>
    </form>
  )
}

function Success(props: {success: boolean}) {
  const classes = useStyles()
  const {t} = useTranslation('auth')

  if (!props.success) {
    return null
  }

  return (
    <div className={clsx(classes.success, 'flex flex-row')}>
      {/* todo change that icon... */}
      <CheckBoxIcon checked />
      <AppTypography variant="action" className={classes.message}>
        {t('success_message')}
      </AppTypography>
    </div>
  )
}
