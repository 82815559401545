import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Counter from 'src/components/forms/Counter'
import {GuestSelectProps} from 'src/types/guestSelect'
import {useEffect, useRef, useState} from 'react'
import {usePropertyCanAcceptAnimals} from 'src/hooks/property'
import AppIcon from 'src/components/elements/icons/AppIcon'
import Hidden from 'src/components/helpers/Hidden'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing}) => ({
  container: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      margin: spacing(2.25, 0),
    },
  },
  icon: {
    width: 13,
    height: 13,
    marginRight: spacing(1),
  },
  smallText: {
    fontSize: 11,
    lineHeight: '13px',
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
}))

export default function GuestSelect(props: {
  maximumGuests?: number
  rules?: string[] | null
  guests: GuestSelectProps
  onChangeGuest: (guestInfo: GuestSelectProps) => void
}) {
  const {maximumGuests, rules, guests, onChangeGuest} = props

  const {t} = useTranslation('common')
  const classes = useStyles()

  const canAcceptAnimals = usePropertyCanAcceptAnimals(rules)
  const timeoutId = useRef<any>(null)
  //init empty
  const [localGuests, setLocalGuests] = useState<GuestSelectProps>({
    adults: 0,
    children: 0,
    infants: 0,
    animals: 0,
  })
  const [reachedToMaximumGuests, setReachedToMaximumGuests] = useState(false)

  useEffect(() => {
    setLocalGuests(guests)
  }, [guests])

  const handleChange =
    (guestType: keyof GuestSelectProps) => (increase: boolean) => () => {
      if (
        increase &&
        reachedToMaximumGuests &&
        (guestType === 'adults' || guestType === 'children')
      ) {
        return
      }

      const newGuests: GuestSelectProps = {...localGuests}
      if (increase) {
        newGuests[guestType] = localGuests[guestType] + 1
      }

      if (!increase && localGuests[guestType] > 0) {
        newGuests[guestType] = localGuests[guestType] - 1
      }
      if (!increase && localGuests[guestType] <= 0) {
        newGuests[guestType] = 0
      }

      setLocalGuests(newGuests)
      const reachedToMaximumGuestsStatus =
        maximumGuests && newGuests.adults + newGuests.children >= maximumGuests
      setReachedToMaximumGuests(!!reachedToMaximumGuestsStatus)

      //like a debounce function, only send to props after 500ms we change selection
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        onChangeGuest(newGuests)
      }, 500)
    }

  const onChange = (guestType: keyof GuestSelectProps) => (value: number) => {
    if (
      maximumGuests &&
      guestType === 'adults' &&
      value + localGuests['children'] > maximumGuests
    ) {
      return
    }

    if (
      maximumGuests &&
      guestType === 'children' &&
      value + localGuests['adults'] > maximumGuests
    ) {
      return
    }

    const newGuests: GuestSelectProps = {...localGuests}
    newGuests[guestType] = value

    onChangeGuest(newGuests)
  }

  return (
    <ul className={classes.container} id="guest_selection">
      <Item
        label={`${t('adults')}s`}
        canIncrease={!reachedToMaximumGuests}
        description={t('adults_description')}
        value={localGuests.adults}
        handleChange={handleChange('adults')}
        onChange={onChange('adults')}
      />
      <Item
        label={t('children')}
        canIncrease={!reachedToMaximumGuests}
        description={t('children_description')}
        value={localGuests.children}
        handleChange={handleChange('children')}
        onChange={onChange('children')}
      />
      <Item
        label={`${t('infants')}s`}
        description={t('infants_description')}
        value={localGuests.infants}
        handleChange={handleChange('infants')}
        onChange={onChange('infants')}
      />
      <div>
        <Item
          disabled={!canAcceptAnimals}
          label={t('animals')}
          description={t('animals_description')}
          value={localGuests.animals}
          handleChange={handleChange('animals')}
          onChange={(e) => {
            if (!canAcceptAnimals) {
              return
            }
            onChange('animals')
          }}
        >
          <Hidden when={canAcceptAnimals}>
            <div className="flex flex-row justify-start items-center">
              <AppIcon name="warning" className={classes.icon} />
              <p className={classes.smallText}>
                {t('property_dont_accept_animals')}
              </p>
            </div>
          </Hidden>
        </Item>
      </div>
    </ul>
  )
}

function Item(props: {
  label: string
  description: string
  value: number
  handleChange: (increase: boolean) => () => void
  onChange: (value: number) => void
  disabled?: boolean
  canIncrease?: boolean
  children?: React.ReactNode
}) {
  const {
    label,
    description,
    value,
    handleChange,
    onChange,
    disabled = false,
    canIncrease = true,
  } = props

  return (
    <li>
      <Container>
        <>
          <div>
            <AppTypography variant="action" neutralColor={800}>
              {label}
            </AppTypography>
            <AppTypography variant="body">{description}</AppTypography>
          </div>
          <Counter
            disabled={disabled}
            value={value}
            canIncrease={canIncrease}
            handleDecrement={handleChange(false)}
            handleIncrement={handleChange(true)}
            handleChange={onChange}
          />
        </>
      </Container>
      {props.children}
    </li>
  )
}

function Container(props: {children: React.ReactElement}) {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      {props.children}
    </div>
  )
}
